.home {
  width: 1160px;
  max-width: 100%;
  margin: auto;
}

.cardsHolder {
  background: white;
  border-radius: 18px;
  margin-top: 20px;
  display: flex;
  padding: 15px 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}