.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #785982;
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
}

.button:hover, .button:active {
  background: #60436a;
}