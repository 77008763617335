.loginErrorBar {
  width: 480px;
  border-radius: 12px;
  background: white;
  border: 2px solid #ED6A6A;
  box-sizing: border-box;
  box-shadow: 0px 3.62642px 6.34624px rgba(0, 0, 0, 0.25);
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  padding: 0 26px;
  height: 70px;
}

.errorContent {
  margin-left: 15px;
}

.errorContent p {
  margin: 0;
}

.errorTitle {
  font-weight: 500;
  font-size: 17px;  
  margin-bottom: 2px;
}

.errorDescription {
  font-size: 15px;
  color: #998080;
}